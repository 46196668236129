import React from 'react'

const ErrorUp = () => {
  return (
    <div className='text-lacivert flex flex-col justify-center md:border-[6px] max-md:border-[2px] border-lacivert p-4 rounded-xl'>
        <span className='md:text-[120px] max-md:text-[30px] font-tiktok text-center'>404</span>
        <span className='md:text-[120px] max-md:text-[30px] font-tiktok text-center'>Page Not Found</span>
    </div>
  )
}

export default ErrorUp