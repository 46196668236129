import React, { useState } from 'react';
import axios from 'axios';
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { frm_true } from '../Stores/frm';
const Contact = () => {
  const dispatch = useDispatch();
  const frm = useSelector((state) => state.frm.value);
  // const [isMessageSent, setIsMessageSent] = useState(false);

  const handleSubmit = async (e) => {


    e.preventDefault();
    // Form işlemleri...
    try {
      // Axios veya fetch ile POST isteği yapılır.
      // Mail gönderildikten sonra başarılı olduğunu işaretlemek için setIsMessageSent(true) kullanılır.
      dispatch(frm_true());
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div className='w-full'>
      <div className='row w-full container mx-auto p-0 m-0'>
        <div className='col-lg-3 col-12 p-0 m-0 max-md:hidden'>
          <div className='flex justify-end items-center p-0 m-0'>
            <img className='p-0 m-0' src="/Images/leftwing.png" alt="" />
          </div>
        </div>
        <div className='col-lg-6 col-12  text-lacivert mx-auto '>
          <h3 className='opacity-100 flex justify-center items-center md:text-[50px] max-md:text-[38px] font-aydin6 text-center'>Bana Mesaj Bırakın</h3>
          <h3 className='opacity-100 flex justify-center items-center md:text-[30px] max-md:text-[20px] font-aydin6 text-center md:pb-3 max-md:pb-0'>psikologbuse@outlook.com</h3>
          <div className=' w-full grid place-items-center container mx-auto  md:border-2 max-md:border-0 border-lacivert md:py-5 rounded-lg max-md:py-0 p-0 m-0'>
            <form class="py-4 d-flex flex-column justify-content-center align-items-center col-10" action="submit.php" method="post">

              <div className='flex flex-col row col-12 my-2'>
                <label className='p-0 m-0 font-aydin6' for="name">Adınız:</label>
                <input class="h-[40px] col-lg-12 border-[1px] font-aydin6 border-lacivert" placeholder="ADINIZ & SOYADINIZ" type="text" id="name" name="name" required />
              </div>
              <div className='flex flex-col row col-12 my-2'>
                <label className='p-0 m-0 font-aydin6' for="email">E-posta Adresiniz:</label>
                <input class="h-[40px] font-aydin6 col-lg-12 border-[1px] border-lacivert" placeholder="E-POSTA" type="email" id="email" name="email" required />
              </div>

              <div className='flex flex-col row col-12 my-2'>
                <label className='p-0 m-0 font-aydin6' for="message">Mesajınız:</label>
                <textarea class="col-lg-12 font-aydin6 max-h-[160px] min-h-[80px] h-[120px] border-[1px] border-lacivert" placeholder="MESAJINIZ" id="message" name="message" rows="4" required></textarea>
              </div>
              <div class="form-group w-full">
                <input type="checkbox" id="kvkk_checkbox" name="kvkk_checkbox"
                  required />
                <label class="kvkk ml-1" for="kvkk_checkbox">Robot Değilim</label>
              </div>
              <input class="font-aydin6 col-12 my-2 border-[2px] border-transparent py-3 bg-lacivert text-white text-[18px] hover:bg-[#121519ab] hover:border-lacivert duration-700 " type="submit" value="Gönder" />
              <div className='col-lg-3 col-12 p-0 m-0 md:hidden absolute z-[-1] opacity-20'>
                <div className='flex justify-center items-center p-0 m-0'>
                  <img className='p-0 m-0' src="/Images/middlewing.png" alt="" />
                </div>
              </div>
              {frm && (
                <div className="text-center text-[20px] font-bold text-green-600 mt-3">
                  Mesajınız Başarıyla Gönderildi!
                </div>
              )}
            </form>

          </div>
        </div>
        <div className='col-lg-3 col-12 p-0 m-0 max-md:hidden'>
          <div className='flex justify-start items-center p-0 m-0'>
            <img className='p-0 m-0' src="/Images/rightwing.png" alt="" />
          </div>
        </div>

      </div>
      <div className='w-full'>
        <PiDotsThreeOutlineVerticalFill className='mx-auto w-[5rem] h-[5rem] my-[2rem] text-lacivert' />
      </div>
    </div>
  );
};

export default Contact;
