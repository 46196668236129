import { createSlice } from "@reduxjs/toolkit";

export const active3 = createSlice({
    name: "active3",
    initialState: {
        value: false,
    },
    reducers: {
        active_blog: (state) => {
            state.value = true;
        },
        active_blog_false: (state) => {
            state.value = false;
        },
    },
});

export const { active_blog , active_blog_false} = active3.actions;


export default active3.reducer;
