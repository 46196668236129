import { createSlice } from "@reduxjs/toolkit";

export const navo = createSlice({
    name: "navo",
    initialState: {
        value: false,
    },
    reducers: {
        navofalse: (state) => {
            state.value = false;
        },
        navotrue: (state) => {
            state.value = true;
        },
        navodegis: (state) => {
            state.value = !state.value;
        },
    },
});

export const { navofalse, navotrue, navodegis } = navo.actions;

export default navo.reducer;