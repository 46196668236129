import React, { useState } from 'react'
import { FaGear } from "react-icons/fa6";
import { useSelector } from 'react-redux';
const Loading = () => {
    const load = useSelector((state) => state.load.value);
    // const efekt = () => {
    //     setTimeout(() => {
    //         setGif(true)
    //     }, 2000)
    // }
    return (
        <div className='flex justify-center items-center w-full h-full bg-white z-50'>
            <div className='flex justify-center items-center'>
                {/* gear */}
                {/* <FaGear className='elg w-[10rem] h-[10rem]' /> */}
                {/* <img src="./gifs/aydin-gif.gif" alt="" /> */}
                <img className='md:w-[25vw] max-md:w-[60vw]' src={`./gifs/aydin-gif.${load ? "gif" : "."}`} alt="" />
            </div>
        </div>
    )
}

export default Loading