import React from 'react'
import Carousel from '../Mid-Components/Carousel'
import ButtonSec from '../Mid-Components/ButtonSec'
import { active_ana, active_ana_false } from '../Stores/active1';
import { active_hak, active_hak_false } from '../Stores/active2';
import { active_blog, active_blog_false } from '../Stores/active3';
import { active_ilet, active_ilet_false } from '../Stores/active4';
import { useDispatch } from 'react-redux';
const AboutUpper = () => {
  const hakactive = () => {
    dispatch(active_ana_false())
    dispatch(active_hak())
    dispatch(active_blog_false())
    dispatch(active_ilet_false())
  }
  const dispatch = useDispatch();
  hakactive();
  return (
    <div className='w-[100%] max-h-[100%] mx-auto flex justify-center items-center max-md:flex-col'>
      <div className='fadeInDown h-[100%] py-20 md:hidden myborder-2 md:border-l-2 border-kgray w-[50%] max-md:w-[100%] flex flex-col justify-center items-center'>
        <div className='w-[80%] d-flex flex-column'>
          <h1 className='md:text-start max-md:text-center font-header text-[350%] text-kgray'>Ben Kimim?</h1>
          <br />
          <p className='md:text-start max-md:text-justify font-main text-[110%] '>Videolarında sürekli olarak okuduğu kitaplardan alıntılar yapması, verdiği bilgilerin güvenirliğini artırıyor. Bununla birlikte gündemine aldığı konuların marjinal oluşu da Buse Aydın’ı diğer psikologlardan ayıran bir özelliktir. Özellikle de toplumca konuşulması uygun bulunmayan konuları açık bir dille ifade etmesi nedeniyle giderek artan bir popülerliğe sahiptir.</p>
          <br />
          <ButtonSec button_text="Randevu Al" />
        </div>
      </div>
      <div className='fadeInDown h-[100%] py-20 max-md:hidden myborder-1 md:border-l-2 border-kgray w-[50%] max-md:w-[100%] flex flex-col justify-center items-center'>
        <div className='w-[80%] d-flex flex-column'>
          <h1 className='md:text-start max-md:text-center font-header text-[350%] text-kgray'>Ben Kimim?</h1>
          <br />
          <p className='md:text-start max-md:text-center font-main text-[110%] '>Videolarında sürekli olarak okuduğu kitaplardan alıntılar yapması, verdiği bilgilerin güvenirliğini artırıyor. Bununla birlikte gündemine aldığı konuların marjinal oluşu da Buse Aydın’ı diğer psikologlardan ayıran bir özelliktir. Özellikle de toplumca konuşulması uygun bulunmayan konuları açık bir dille ifade etmesi nedeniyle giderek artan bir popülerliğe sahiptir.</p>
          <br />
          <ButtonSec button_text="Randevu Al" />
        </div>
      </div>
      <div className='fadeInNoOpacity flex w-[50%]  justify-center items-center max-xl:hidden'>
        {/* <img className='w-[70%]' src="./Images/free.png" alt="" /> */}
        {/* <img className='max-h-[60vh] z-20' src="./Images/hakkimda.png" alt="" /> */}
        <img className='absolute w-[25rem] z-10 translate-x-[5rem] translate-y-[-2rem]' src="./Images/buseaydin-1.jpg" alt="" />
        <img className='absolute w-[20rem] z-10  translate-x-[-5rem] translate-y-[4.5rem]' src="./Images/buseaydin-3.jpg" alt="" />
      </div>
    </div>
  )
}

export default AboutUpper