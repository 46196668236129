import React from 'react'
import Header from './Header'
import UpperUp from './UpperUp'
import UpperDown from './UpperDown'
import Body from './Body'
import SmoothLayer from '../Mid-Components/SmoothLayer'
import Footer from './Footer'
import OpenHam from '../Mid-Components/OpenHam'
import { useSelector } from 'react-redux'
import YoutubeScreen from '../Contents/YoutubeScreen'
import { Helmet } from 'react-helmet'
const AllPage = () => {
  const ham = useSelector((state) => state.ham.value);
  const you = useSelector((state) => state.you.value);
  return (
    <div className={` min-h-[100vh] `}>
    <Helmet>
    <meta name="description" content="Psikolog Buse Aydın, ilişki st olarak bilinen sosyal medya fenomeni. TikTok, Instagram ve YouTube'da kadın, erkek, psikoloji ve ilişkiler ile ilgili içerikler paylaşıyor. Uzman görüşleri ve ipuçları için takip edin." />
  <meta name="keywords" content="Psikolog Buse Aydın, Aydın Psikoloji, Buse Aydın, ilişki sti, sosyal medya fenomeni, psikoloji, ilişki, uzman görüşleri, ipuçları, TikTok, Instagram, YouTube, Kick, Kadın, Erkek, ilişki" />
    </Helmet>
          <div className={`  ${ham ? "hamclose" : "hamopen"}`}>
            <OpenHam />
          </div>
          
          <div className={` ${you ? "hamclose" : "hamopen "}`}>
            <YoutubeScreen />
          </div>
          <Header />
          <UpperUp />
          <UpperDown />
          <Body />
          {/* <div className='flex justify-center items-center'>
        <SmoothLayer />
      </div> */}
          <Footer />
        </div>

        )
}

        export default AllPage


        {/* <div>
        <Owldemo />
      </div> */}