import logo from './logo.svg';
import './App.css';
import Load, { loadtrue } from './Stores/load';
import { loadfalse } from './Stores/load';
import { loaddegis } from './Stores/load';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import Loading from './Mid-Components/Loading';
import Watch from './Mid-Components/Watch';
import OpenHam from './Mid-Components/OpenHam';
import AllPage from './Page-Parts/AllPage';

function App() {
  
  const dispatch = useDispatch();
  const load = useSelector((state) => state.load.value);
  const watch = useSelector((state) => state.watch.value);
  const you = useSelector((state) => state.you.value);
  const ham = useSelector((state) => state.ham.value);
  const efekt = () => {
    setTimeout(() => {
      dispatch(loadfalse())
    }, 2000)

  }
  efekt()
  return (
    <div className={`App  `}>
      <div className={`${load ? "h-screen" : "hidden"}`}>
        <Loading />
      </div>
      <div className={` ${watch ? "hidden" : "h-screen"}`}>
        <Watch />
      </div>
      <div className={`${load ? "hidden" : "acilis"} ${watch ? "acilis" : "hidden"} ${ham ? "h-screen" : ""} `}>
      <div className={`${ham?"":"hamopen-opac"}`}></div>

          <AllPage />

      </div>
      {/* {load ? <Loading /> : <Loading />} */}
    </div>
  );
}

export default App;
