import React from 'react'
import { useSelector } from 'react-redux';

const Button = ({ button_text, button_rounded }) => {
    const navo = useSelector((state) => state.navo.value);
    return (
        <button className={`flex justify-center items-center text-center ${navo ? "text-white border-white " : "bg-lacivert border-lacivert text-white"} font-header border-2 rounded-${button_rounded} py-1 px-4 `}>
            <span className='duration-100 hover:scale-110'> {button_text}</span>
        </button>
    )
}

export default Button