import React from 'react'
import { Route, Routes, Link, NavLink } from "react-router-dom";
import active1 from '../Stores/active1';
import active2 from '../Stores/active2';
import active3 from '../Stores/active3';
import active4 from '../Stores/active4';
import { useSelector } from 'react-redux';
import Button from '../Mid-Components/Button';
import { FaInstagram } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaTiktok } from "react-icons/fa";
import { useDispatch } from 'react-redux';
import { watchtrue } from '../Stores/watch';
import { watchfalse } from '../Stores/watch';
const Footer = () => {
    const active1 = useSelector((state) => state.active1.value);
    const active2 = useSelector((state) => state.active2.value);
    const active3 = useSelector((state) => state.active3.value);
    const active4 = useSelector((state) => state.active4.value);
    const watch = useSelector((state) => state.watch.value);
    const dispatch = useDispatch();
    const anailetisim = () => {
        dispatch(watchfalse())
        setTimeout(() => {
            dispatch(watchtrue())
        }, 1000)
    }
    return (

        <div className={`w-full `}>
            <div className='w-full bg-lacivert text-white py-10'>
                <div className='w-full  container mx-auto p-0 m-0'>
                    <div class=" font-header w-full justify-center items-center place-items-center md:grid md:grid-cols-4 md:grid-flow-row max-md:grid-rows max-md:grid-flow-col max-md:gap-10">
                        <div className='w-full flex justify-center h-full items-center '>
                            <img className='max-md:w-[35%] md:w-[60%]' src="./Icons/aydin-png-no-bg-white.png" alt="" />
                        </div>
                        <nav className='w-full flex flex-col max-md:flex-row justify-center max-md:justify-around max-md:my-10 items-center border-l-[2px] border-r-[1px] border-lacivert2'>

                            <NavLink to="/" className={`${active1 ? "activeeFoot" : ""} border-b-[1px] border-b-transparent  text-white no-underline md:mb-2`}>Anasayfa</NavLink>
                            <NavLink to="/hakkimda" className={`${active2 ? "activeeFoot" : ""} border-b-[1px] border-b-transparent text-white no-underline md:my-2`}>Hakkımda</NavLink>
                            <NavLink to="/sosyalmedya" className={`${active3 ? "activeeFoot" : ""} border-b-[1px] border-b-transparent text-white no-underline md:my-2`}>Sosyal Medya</NavLink>
                            <NavLink to="/iletisim" className={`${active4 ? "activeeFoot" : ""} border-b-[1px] border-b-transparent text-white no-underline md:mt-2`}>İletişim</NavLink>
                        </nav>
                        <div className='border-r-[2px] border-l-[1px] border-lacivert2 w-full flex justify-center items-center h-full'>
                            <div className=' h-full flex flex-col max-md:flex-row justify-around max-md:justify-around max-md:items-center md:items-start  max-md:w-full md:w-fit '>
                                <a className='flex justify-start items-center text-white no-underline' target='_blank' href="https://www.instagram.com/psikologbuseaydin/?hl=en">
                                    <FaInstagram className='w-[1.6rem] h-[1.6rem] mr-1' />
                                    <p className='max-md:hidden ml-1 p-0 text-center text-[0.7rem]'>psikologbuseaydin</p>
                                </a>
                                <a className='flex justify-start items-center text-white no-underline' target='_blank' href="https://www.youtube.com/@Aydinpsikoloji">
                                    <FaYoutube className='w-[1.6rem] h-[1.6rem] mr-1' />
                                    <p className='max-md:hidden ml-1 p-0 text-center text-[0.7rem]'>Aydinpsikoloji
                                    </p>
                                </a>
                                <a className='flex justify-start items-center text-white no-underline' target='_blank' href="https://www.tiktok.com/@psikologbuseofficial?lang=en">
                                    <FaTiktok className='w-[1.6rem] h-[1.6rem] mr-1' />
                                    <p className='max-md:hidden ml-1 p-0 text-center text-[0.7rem]'>psikologbuseofficial</p>
                                </a>
                            </div>
                        </div>
                        <div className='w-full h-full grid justify-center items-center '>
                            <div className={`max-md:hidden w-full font-main  h-full flex justify-center items-center  `}>
                                <NavLink to="/iletisim" onClick={() => {
                                    anailetisim();
                                }}>
                                    <Button button_text={`ONLİNE RANDEVU`} button_rounded={"[1rem]"} />
                                </NavLink>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
            <div className='w-full bottom-0  bg-lacivert text-white p-0 m-0'>
                <div className='container mx-auto p-0 m-0'>
                    <p className='text-[9px] text-center p-0 m-0'>Ajansflu / K.A</p>
                </div>
            </div>
        </div>
    )
}

export default Footer