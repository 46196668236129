import { createSlice } from "@reduxjs/toolkit";

export const ham = createSlice({
    name: "ham",
    initialState: {
        value: true,
    },
    reducers: {
        ham_false: (state) => {
            state.value = false;
        },
        ham_true: (state) => {
            state.value = true;
        },
        ham_change: (state) => {
            state.value = !state.value;
        },

    },
});

export const { ham_false, ham_true, ham_change } = ham.actions;


export default ham.reducer;