import React from 'react'
import { SiYoutubeshorts } from "react-icons/si";
const YoutubeVideo = ({ video_code, video_head }) => {
    return (
        <div className='md:w-[100%] pt-0 p-6 md:h-[40rem] overflow-hidden'>
           <iframe className='w-full h-full rounded-2xl' src={`https://www.youtube.com/embed/${video_code}`} title="Kadın Mağduru Erkekler!" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            <h5 className='text-center font-header font-semibold over-hid-single'>{video_head}</h5>
        </div>
    )
}
export default YoutubeVideo