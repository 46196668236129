import { createSlice } from "@reduxjs/toolkit";

export const active2 = createSlice({
    name: "active2",
    initialState: {
        value: false,
    },
    reducers: {
        active_hak: (state) => {
            state.value = true;
        },
        active_hak_false: (state) => {
            state.value = false;
        },
    },
});

export const { active_hak , active_hak_false} = active2.actions;


export default active2.reducer;
