import { configureStore } from "@reduxjs/toolkit";
import testReducer from "./Stores/ham";
import loadReducer from "./Stores/load"
import navoReducer from "./Stores/navo"
import watchReducer from "./Stores/watch"
import hamReducer from "./Stores/ham"
import youReducer from "./Stores/you"
import active1Reducer from "./Stores/active1"
import active2Reducer from "./Stores/active2"
import active3Reducer from "./Stores/active3"
import active4Reducer from "./Stores/active4"
import frmReducer from "./Stores/frm"


export default configureStore({
    reducer: {
        test: testReducer,
        load: loadReducer,
        navo: navoReducer,
        watch: watchReducer,
        ham: hamReducer,
        you: youReducer,
        frm: frmReducer,
        active1: active1Reducer,
        active2: active2Reducer,
        active3: active3Reducer,
        active4: active4Reducer,
    },
});