import React from 'react'
import { NavLink, nav } from 'react-router-dom'
import { watchfalse, watchtrue } from '../Stores/watch'
import { useDispatch } from 'react-redux'

const BlogSingle = ({ blog_header, blog_image, blog_id, blog_header_css, blog_page, uzmanliklar }) => {
    const dispatch = useDispatch()
    const load = () => {
        dispatch(watchfalse())
        setTimeout(() => {
            dispatch(watchtrue())
        }, 1000)
    }
    return (

        <nav onClick={load} id={blog_id} className='w-full p-10  blog-part '>
            <NavLink style={{ textDecoration: 'none' }} to={blog_page} class="w-full overflow-hidden blog-part-top">
                <div className='absolute flex flex-col justify-center items-center w-[3rem] h-[3rem] bg-white text-lacivert border-[2px] border-lacivert text-center font-aydin6 overflow-hidden translate-y-[15px] translate-x-[-15px] rounded-sm'>
                    <p className='w-full text-[1.3rem] font-bold'>
                        {uzmanliklar}
                    </p>
                </div>
                <img className="blog-part-img object-cover w-full h-[18rem] rounded-[0.5rem]" src={blog_image} />
                <h3 className={`over-hid text-center font-header pt-2 ${blog_header_css}`}>{blog_header}</h3>
                {/* <p className={`over-hid text-center font-main text-[14px] ${blog_header_css}`}>{blog_text}</p> */}
            </NavLink>
        </nav>
    )
}

export default BlogSingle