import { createSlice } from "@reduxjs/toolkit";

export const active4 = createSlice({
    name: "active4",
    initialState: {
        value: false,
    },
    reducers: {
        active_ilet: (state) => {
            state.value = true;
        },
        active_ilet_false: (state) => {
            state.value = false;
        },
    },
});

export const { active_ilet , active_ilet_false} = active4.actions;


export default active4.reducer;
