import React from 'react'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { ham_false } from '../Stores/ham';
import { ham_true } from '../Stores/ham';
import { FaInstagram } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaTiktok } from "react-icons/fa";
import { Route, Routes, Link, NavLink } from "react-router-dom";
import OutsideClickHandler from 'react-outside-click-handler';
import { active_ana } from '../Stores/active1';
import { active_hak } from '../Stores/active2';
import { active_blog } from '../Stores/active3';
import { active_ilet } from '../Stores/active4';
import { active_ana_false } from '../Stores/active1';
import { active_hak_false } from '../Stores/active2';
import { active_blog_false } from '../Stores/active3';
import { active_ilet_false } from '../Stores/active4';
import { watchtrue } from '../Stores/watch';
import { watchfalse } from '../Stores/watch';
const OpenHam = () => {
  const active1 = useSelector((state) => state.active1.value);
  const active2 = useSelector((state) => state.active2.value);
  const active3 = useSelector((state) => state.active3.value);
  const active4 = useSelector((state) => state.active4.value);
  const ham = useSelector((state) => state.ham.value);
  const navo = useSelector((state) => state.navo.value);
  const dispatch = useDispatch();
  const anaactive = () => {
    dispatch(active_ana())
    dispatch(active_hak_false())
    dispatch(active_blog_false())
    dispatch(active_ilet_false())
    dispatch(ham_true())
    dispatch(watchfalse())
    setTimeout(() => {
      dispatch(watchtrue())
    }, 1000)
    document.body.style.overflow = 'unset';
  }
  const anahakkimda = () => {
    dispatch(active_ana_false())
    dispatch(active_hak())
    dispatch(active_blog_false())
    dispatch(active_ilet_false())
    dispatch(ham_true())
    dispatch(watchfalse())
    setTimeout(() => {
      dispatch(watchtrue())
    }, 1000)
    document.body.style.overflow = 'unset';
  }
  const anablog = () => {
    dispatch(active_ana_false())
    dispatch(active_hak_false())
    dispatch(active_blog())
    dispatch(active_ilet_false())
    dispatch(ham_true())
    dispatch(watchfalse())
    setTimeout(() => {
      dispatch(watchtrue())
    }, 1000)
    document.body.style.overflow = 'unset';
  }
  const anailetisim = () => {
    dispatch(active_ana_false())
    dispatch(active_hak_false())
    dispatch(active_blog_false())
    dispatch(active_ilet())
    dispatch(ham_true())
    dispatch(watchfalse())
    setTimeout(() => {
      dispatch(watchtrue())
    }, 1000)
    document.body.style.overflow = 'unset';
  }
  const kapanis = () => {
    dispatch(ham_true())
    document.body.style.overflow = 'unset';
  }
  // 
  return (
    <div className={`flex justify-center items-start w-[100vw]  overflow-hidden z-[2000] `}>
      <div className={`min-h-screen fixed justify-center items-start object-cover w-[100vw] `}>
        <OutsideClickHandler className={``} onOutsideClick={() => {
          kapanis();
        }}>
          <nav className={`ham-ul text-[150%] w-[100vw] font-header z-[2000] ${ham ? "list-close" : "list-open"}  p-0 m-0 flex flex-col d-flex justify-center items-center`}>
     
              <img className={`${ham ? "listcl" : ""} w-[40%] mt-[3rem] `} src="./Icons/aydin-png-no-bg-white.png" alt="" />

            <div className='h-[100%] flex flex-col justify-center items-center w-[100%]'>
              <NavLink onClick={anaactive} to="/" className={`${active1 ? "activee-2" : "nonactiveham"} navAnasayfa  no-underline hover:scale-110 duration-300 w-[80%] text-center my-[0.5rem] ${ham ? "listcl" : "list1"}`}>Anasayfa</NavLink>
              <NavLink onClick={anahakkimda} to="/hakkimda" className={`${active2 ? "activee-2" : "nonactiveham"} navHakkimda  no-underline hover:scale-110 duration-300 w-[80%] text-center my-[0.5rem] ${ham ? "listcl" : "list2"}`}>Hakkımda</NavLink>
              <NavLink onClick={anablog} to="/sosyalmedya" className={`${active3 ? "activee-2" : "nonactiveham"} navBlog  no-underline hover:scale-110 duration-300 w-[80%] text-center my-[0.5rem] ${ham ? "listcl" : "list3"}`}>SosyalMedya</NavLink>
              <NavLink onClick={anailetisim} to="/iletisim" className={`${active4 ? "activee-2" : "nonactiveham"} navIletisim  no-underline hover:scale-110 duration-300 w-[80%] text-center my-[0.5rem] ${ham ? "listcl" : "list4"}`}>İletişim
              </NavLink>
            </div>
            <div className={`w-[80%] flex justify-center items-center text-center mb-[3rem] ${ham ? "listcl" : "list5"}`}>
              <a className='text-white mx-2' target="_blank" href="https://www.instagram.com/psikologbuseaydin/">
              <FaInstagram className='mx-[0.8rem]' />
              </a>
              <a className='text-white mx-2' target="_blank" href="https://www.youtube.com/@Aydinpsikoloji">
              <FaYoutube className='mx-[0.8rem]' />
              </a>
              <a className='text-white mx-2' target="_blank" href="https://www.tiktok.com/@psikologbuseofficial?lang=en">
              <FaTiktok className='mx-[0.8rem]' />
              </a>
            </div>
          </nav>
        </OutsideClickHandler>
      </div>
    </div>
  )
}

export default OpenHam