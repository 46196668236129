import React from 'react'
import { Route, Routes, Link, NavLink } from "react-router-dom";
import HomepageUpper from '../Pages/HomepageUpper'
import AboutUpper from '../Pages/AboutUpper';
import SosyalMedyaUpper from '../Pages/SosyalMedyaUpper';
import ContactUpper from '../Pages/ContactUpper';
import ErrorUp from '../Pages/ErrorUp';
const UpperDown = () => {
  return (
    <div className={`container h-[90vh] p-0 mx-auto w-[100%] flex justify-center items-center`}>
      <Routes>
        <Route path='/*' element={<ErrorUp />} />
        <Route path='/' element={<HomepageUpper />} />
        <Route path='/hakkimda' element={<AboutUpper />} />
        <Route path='/sosyalmedya' element={<SosyalMedyaUpper />} />
        <Route path='/iletisim' element={<ContactUpper />} />
      </Routes>
    </div>
  )
}

export default UpperDown