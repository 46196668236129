import { createSlice } from "@reduxjs/toolkit";

export const active1 = createSlice({
    name: "active1",
    initialState: {
        value: false,
    },
    reducers: {
        active_ana: (state) => {
            state.value = true;
        },
        active_ana_false: (state) => {
            state.value = false;
        },
    },
});

export const { active_ana , active_ana_false} = active1.actions;


export default active1.reducer;
