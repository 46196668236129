import React from 'react'
import { Route, Routes, Link, NavLink } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { navotrue } from '../Stores/navo';
import { navofalse } from '../Stores/navo';
import { watchtrue } from '../Stores/watch';
import { watchfalse } from '../Stores/watch';
import { GiHamburger, GiHamburgerMenu } from "react-icons/gi";
import { ham_true } from '../Stores/ham';
import { ham_false } from '../Stores/ham';
import { ham_change } from '../Stores/ham';
import { active_ana } from '../Stores/active1';
import { active_hak } from '../Stores/active2';
import { active_blog } from '../Stores/active3';
import { active_ilet } from '../Stores/active4';
import { active_ana_false } from '../Stores/active1';
import { active_hak_false } from '../Stores/active2';
import { active_blog_false } from '../Stores/active3';
import { active_ilet_false } from '../Stores/active4';
import Button from '../Mid-Components/Button';
const Header = () => {
  const dispatch = useDispatch();
  // const navo = useSelector((state) => state.navo.value);
  // const navo2 = useSelector((state) => state.navo2.value);
  // const nav2 = useSelector((state) => state.nav2.value);
  const watch = useSelector((state) => state.watch.value);
  const ham = useSelector((state) => state.ham.value);
  const navo = useSelector((state) => state.navo.value);
  const active1 = useSelector((state) => state.active1.value);
  const active2 = useSelector((state) => state.active2.value);
  const active3 = useSelector((state) => state.active3.value);
  const active4 = useSelector((state) => state.active4.value);


  const anaactive = () => {
    dispatch(watchfalse())
    setTimeout(() => {
      dispatch(watchtrue())
    }, 1000)
  }
  const anahakkimda = () => {
    dispatch(watchfalse())
    setTimeout(() => {
      dispatch(watchtrue())
    }, 1000)
  }
  const anablog = () => {
    dispatch(watchfalse())
    setTimeout(() => {
      dispatch(watchtrue())
    }, 1000)
  }
  const anailetisim = () => {
    dispatch(watchfalse())
    setTimeout(() => {
      dispatch(watchtrue())
    }, 1000)
  }

  const kaydir = () => {
    if (window.scrollY == 0) {
      dispatch(navofalse());
      // dispatch(navo2false())
      // dispatch(nav2false());

    }
    else {
      dispatch(navotrue());
      // dispatch(navo2true());
      // dispatch(nav2true())
    }
  }

  const hamburger = () => {
    dispatch(ham_false())
    document.body.style.overflow = 'hidden';
  }

  // BURASI GEÇİŞLER İÇİN
  // onClick={() => {
  //   efekt();
  // }}
  // BURASI MOBİL HAMBURGER MENÜ
  // onClick={hamburger}



  window.onscroll = kaydir;
  return (
    <div className={`w-[100%] ${navo ? "border-lacivert bg-lacivert" : "border-ghost1"} duration-300 fixed z-40`}>
      <div className={`container mx-auto  p-0 m-0 duration-300    z-50 flex justify-between items-center h-[10vh] `}>
        <div className={` max-md:hidden w-[30%]  h-full flex justify-center items-center duration-500`}>
          <nav className={`z-50  p-0 m-0 flex w-full h-full justify-around items-center text-[108%] duration-50 ${navo ? "font-[500]" : "font-[600]"}`}>
            <NavLink className={`${active1 ? "activee" : ""} font-nav font-header ${navo ? "nonactive-2" : "nonactive"} `} onClick={() => {
              anaactive();
            }} to="/">Anasayfa</NavLink>
            <NavLink className={`${active2 ? "activee" : ""}  font-nav font-header ${navo ? "nonactive-2" : "nonactive"} `} onClick={() => {
              anahakkimda();
            }} to="/hakkimda">Hakkımda</NavLink>
            <NavLink className={`${active3 ? "activee" : ""}  font-nav font-header ${navo ? "nonactive-2" : "nonactive"} `} onClick={() => {
              anablog();
            }} to="/sosyalmedya">Sosyal Medya</NavLink>
            <NavLink className={`${active4 ? "activee" : ""}  font-nav font-header ${navo ? 'nonactive-2' : "nonactive"} `} onClick={() => {
              anailetisim();
            }} to="/iletisim">İletişim</NavLink>
          </nav>
        </div>
        <div className={`w-[30%]  duration-1000 max-md:w-[70%] h-full  flex justify-center  ${navo ? "" : "lg:translate-y-5 lg:scale-150 "} items-center max-md:items-center`}>
        <NavLink className="flex justify-center max-md:justify-start max-md:ml-5 items-center h-fit w-fit" to="/">
          <img className={`max-lg:w-[75%] max-md:w-[55%] w-[35%]`} src={`./Icons/aydin-png-no-bg-${navo ? "white" : "black"}.png`} alt="" />
          </NavLink>
        </div>
        <div className={`max-md:hidden w-[30%] font-main  h-full flex justify-center items-center  `}>
          <NavLink to="/iletisim" onClick={() => {
              anailetisim();
            }}>
            <Button button_text={`ONLİNE RANDEVU`} button_rounded={"[1rem]"} />
          </NavLink>
        </div>
        <div className={`md:hidden max-md:w-[30%]  font-main  h-full flex justify-center items-center  `}>
          <GiHamburgerMenu onClick={hamburger} className={`duration-300 ${navo ? "text-white" : ""} cursor-pointer w-[35%] h-[35%]`} />
        </div>
      </div>
    </div>
  )
}

export default Header




{/* <div className={` h-full w-[50%] flex justify-center items-center duration-200 max-md:hidden ${navo? "w-[0%] h-[0%] opacity-0 hidden" : ""} `}>
        <nav className='back-side h-full w-full flex justify-end items-center p-0 m-0 bg-lacivert'>
          <NavLink onClick={() => {
            efekt();
          }} to="/Projelerimiz" className='header-text-first flex justify-center items-center h-full w-[25%] text-center bg-lacivert text-white font-bold text-lg'>Projelerimiz</NavLink>
          <div className='flex justify-center items-center h-full w-[25%] text-center bg-fosfor'>
            <NavLink onClick={() => {
              efekt();
            }} to="/Hakkimizda" className='header-text-second flex justify-center items-center h-full w-full text-center rounded-tr-[1rem] bg-lacivert text-white font-bold text-lg' >Hakkımızda</NavLink>
          </div>
          <NavLink onClick={() => {
            efekt();
          }} to="/Iletisim" className='header-yellow-text flex justify-center items-center h-full w-[25%] text-center bg-fosfor rounded-bl-[1rem] text-lacivert font-bold text-lg'>Bize Ulaşın!</NavLink>

        </nav>
      </div> */}