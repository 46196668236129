import { createSlice } from "@reduxjs/toolkit";

export const load = createSlice({
    name: "load",
    initialState: {
        value: true,
    },
    reducers: {
        loadfalse: (state) => {
            state.value = false;
        },
        loadtrue: (state) => {
            state.value = true;
        },
        loaddegis: (state) => {
            state.value = !state.value;
        },
    },
});

export const { loadfalse, loadtrue, loaddegis } = load.actions;

export default load.reducer;