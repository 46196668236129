import React from 'react'
import { SiYoutubetv } from "react-icons/si";

const YoutubeImg = ({ video_code, video_head }) => {
    return (
        <div className='md:w-[100%] overflow-hidden cursor-pointer  p-6 md:hover:scale-110 duration-300'>
            <SiYoutubetv className=' text-white absolute p-2 md:w-[4rem] md:h-[4rem] max-md:w-[3rem] max-md:h-[3rem] translate-x-3 translate-y-3 bg-red-700 rounded-full '/>
            <img className=' rounded-2xl w-full  object-cover object-left' src={`https://img.youtube.com/vi/${video_code}/maxresdefault.jpg`} alt="YouTube Video Thumbnail" />
            <h5 className=' text-center font-header font-semibold over-hid-single '>{video_head}</h5>
        </div>
    )
}

export default YoutubeImg