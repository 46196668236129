import React from 'react'
import "./Carousel.css"
const Carousel = () => {
  return (
    <div className='font-aydin6 w-100 h-100 flex justify-center items-center'>
      <div className='top-lib relative mr-8 caro-fit flex justify-center items-center w-[37.5%] h-[30rem] rounded-l-full '>

      <div className='hide-lib'></div>
        <img className='img-lib h-[30rem] caro-image' src="./Images/buseaydin-2.jpg" alt="" />
        <h3 className='text-lib'></h3>
 
      </div>
      <div className='top-lib relative caro-fit flex justify-center items-center w-[25%] h-[30rem] '>
      <div className='hide-lib'></div>
        <img className='img-lib h-[30rem] caro-image object-center' src="./Images/psk.png" alt="" />
        <h3 className='text-lib'>Tedavi</h3>
      </div>
      <div className='top-lib relative ml-8 caro-fit flex justify-center items-center w-[37.5%] h-[30rem] rounded-tr-full '>
        <div className='hide-lib'></div>
        <img className='img-lib h-[30rem] caro-image' src="./Images/buseaydin-3.jpg" alt="" />
        <h3 className='text-lib'>Danışmanlık</h3>
      </div>


    </div>

  )
}

export default Carousel