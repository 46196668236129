import React from 'react'
import { active_ana, active_ana_false } from '../Stores/active1';
import { active_hak, active_hak_false } from '../Stores/active2';
import { active_blog, active_blog_false } from '../Stores/active3';
import { active_ilet, active_ilet_false } from '../Stores/active4';
import { useDispatch } from 'react-redux';
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
const About = () => {
  const hakactive = () => {
    dispatch(active_ana_false())
    dispatch(active_hak())
    dispatch(active_blog_false())
    dispatch(active_ilet_false())
  }
  const dispatch = useDispatch();
  hakactive();
  return (
    <div className='w-full font-main'>
      <div className='w-full  container mx-auto p-0 m-0'>


        <div class="grid grid-rows">
          <div className='max-md:hidden overflow-hidden w-[100%] h-[25rem] flex flex-col justify-center items-center rounded-[1rem]'>
            <img className='object-cover w-[100%]' src="/Images/psychologabout.png" alt="" />
          </div>
          <div className='flex flex-col justify-center items-center md:pt-[1rem] max-md:w-[80%] mx-auto'>
            <h4 className='text-[2rem] font-bold'>Ben Kimim?</h4>
            <p className='text-[1.2rem] md:py-[1rem] max-md:text-justify max-md:py-[1rem]'>Ben psikolog Buse Aydın.
              Cinsel Konular asıl çalışma alanım.
              Lisans eğitimimi Üsküdar üniversitesi’nde tamamladım. Lisans eğitimimi tamamladıktan bir yıl sonra 6 ay süren eğitimle cinsel işlev bozukluklarında çalışabilmek için Cinsel Konularst sertifikamı aldım. Eğitimimin ardından Aile danışmanlığı alanında yüksek lisansıma başladım.
              ”Aydın Psikoloji” adlı YouTube kanalıma 2023 yılından beri video yüklemekteyim.Ele aldığım konular “Cinsel işlev bozuklukları” ve kırmızı hap praksiyolojisinin ışığında “kadın erkek ilişkileri.”
              Vizyonum çiftlerin cinsel işlev bozukluklarını  ile çözerken kadın erkek ilişkilerinde ise kadın ve erkeğin doğasından da bilgiler sunarak   ilişkilere daha sağlıklı bir yaklaşım getirmektir.
              “İlişkide problem kişinin değil çifttin problemidir” mentalitesiyle yola çıkmaktayım.
              Sağlıklı günler dilerim.</p>
            <p className='text-[0.8rem] max-md:text-cente max-md:text-justify'>Kişiler bir problem yaşadığında kendilerine fazla yükleniyorlar ancak bunlar hayatın akışında yaşanan şeyler. Hayat tam olarak bu. Bizim hatamız bize yapılan hatalar bizim bu sorunların neresinde olduğumuz ve neresinde olmadığımız. Sağlıklı bir bakış açısı. Durumlar belki de sandığımız kadar kötü değildir ya da daha kötüdür :) ve evet bu da hayatın olağan akışında olan şeyler.
              #kötühissetmeknormaldir</p>
          </div>


        </div>

      </div>
      <div className='w-full'>
        <PiDotsThreeOutlineVerticalFill className='mx-auto w-[5rem] h-[5rem] my-[2rem] text-lacivert' />
      </div>
    </div>
  )
}

export default About