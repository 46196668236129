import React from 'react'
import OutsideClickHandler from 'react-outside-click-handler';
import { you_true } from '../Stores/you';
import { you_false } from '../Stores/you';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
const YoutubeScreen = () => {
    const you = useSelector((state) => state.you.value);
    const dispatch = useDispatch()
    const kapanis = () => {
        dispatch(you_true())
        document.body.style.overflow = 'unset';
    }
    return (
        <div className={`fixed flex justify-center items-center w-full h-screen p-0 m-0  overflow-hidden z-[999]  ${you ? "" : ""}`}>
            <div className={`object-cover ${you ? "" : ""}`}>
                {/* <OutsideClickHandler className={``} onOutsideClick={() => {
                    kapanis();
                }}>
                    {you ? <iframe className={` w-[75vw] h-[75vh] max-md:h-[25vh] `} src="" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" ></iframe> : <iframe className={` w-[75vw] h-[75vh] max-md:h-[25vh] `} src="https://www.youtube.com/embed/xNJQGZgL6tQ?si=NDZQQo4roV0QaXau" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>}

                </OutsideClickHandler> */}


            <iframe className='w-full' src="https://www.youtube.com/embed/xNJQGZgL6tQ" title="Kadın Mağduru Erkekler!" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
        </div>
    )
}
export default YoutubeScreen