import React, { useState } from 'react'
import { FaGear } from "react-icons/fa6";
import { useSelector } from 'react-redux';
const Watch = () => {
    const watch = useSelector((state) => state.watch.value);
    // const efekt = () => {
    //     setTimeout(() => {
    //         setGif(true)
    //     }, 2000)
    // }
    return (
        <div className='flex justify-center items-center w-full h-full bg-white z-50'>
            <div className='flex justify-center items-center'>
                {/* gear */}
                {/* <FaGear className='elg w-[10rem] h-[10rem]' /> */}
                <img className={`${watch?"":"logo-acil"} md:w-[25vw] max-md:w-[60vw]`} src={`./Icons/aydin-png-no-bg-black.${watch?".":"png"}`} alt="" />

              
            </div>
        </div>
    )
}

export default Watch