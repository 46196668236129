import { createSlice } from "@reduxjs/toolkit";

export const you = createSlice({
    name: "you",
    initialState: {
        value: true,
    },
    reducers: {
        you_false: (state) => {
            state.value = false;
        },
        you_true: (state) => {
            state.value = true;
        },
        you_change: (state) => {
            state.value = !state.value;
        },

    },
});

export const { you_false, you_true, you_change } = you.actions;


export default you.reducer;