import React from 'react'
import { NavLink } from 'react-router-dom'
const ButtonSec = ({ button_text, button_rounded }) => {
  return (
    <button className='w-full'>
      <NavLink to="/iletisim">
        <div className={`bg-lacivert border-lacivert text-white flex justify-center items-center text-center font-header border-2 rounded-${button_rounded} py-1 px-4`}>
          <span className='duration-100 hover:scale-110'> {button_text}</span>
        </div>
      </NavLink>
    </button>
  )
}

export default ButtonSec