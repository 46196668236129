import { createSlice } from "@reduxjs/toolkit";

export const watch = createSlice({
    name: "watch",
    initialState: {
        value: true,
    },
    reducers: {
        watchfalse: (state) => {
            state.value = false;
        },
        watchtrue: (state) => {
            state.value = true;
        },
        watchdegis: (state) => {
            state.value = !state.value;
        },
    },
});

export const { watchfalse, watchtrue, watchdegis } = watch.actions;

export default watch.reducer;