import { createSlice } from "@reduxjs/toolkit";

export const frm = createSlice({
    name: "frm",
    initialState: {
        value: false,
    },
    reducers: {
        frm_false: (state) => {
            state.value = false;
        },
        frm_true: (state) => {
            state.value = true;
        },
        frm_change: (state) => {
            state.value = !state.value;
        },

    },
});

export const { frm_false, frm_true, frm_change } = frm.actions;

export default frm.reducer;