import React from 'react'
import { active_ana, active_ana_false } from '../Stores/active1';
import { active_hak, active_hak_false } from '../Stores/active2';
import { active_blog, active_blog_false } from '../Stores/active3';
import { active_ilet, active_ilet_false } from '../Stores/active4';
import { useDispatch } from 'react-redux';
import BlogSingle from '../Contents/BlogSingle';
import Youtube from './SosyalMedya';
import { NavLink } from 'react-router-dom';
import { watchtrue } from '../Stores/watch';
import { watchfalse } from '../Stores/watch';
import YoutubeImg from '../Contents/YoutubeImg';
import YoutubeVideo from '../Contents/YoutubeVideo';
import { SiYoutubeshorts } from "react-icons/si";
import { TfiYoutube } from "react-icons/tfi";
import { SiYoutubetv } from "react-icons/si";
import { ImYoutube2 } from "react-icons/im";
import { you_false } from '../Stores/you';
import { useSelector } from 'react-redux';
import { BsPersonWalking } from "react-icons/bs";
import { FaPersonArrowUpFromLine } from "react-icons/fa6";
import { HiHeart } from "react-icons/hi";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";



const Homepage = () => {
  const you = useSelector((state) => state.you.value);
  const youtube = () => {
    dispatch(you_false())
    document.body.style.overflow = 'hidden';
  }

  const anaactive = () => {
    dispatch(active_ana())
    dispatch(active_hak_false())
    dispatch(active_blog_false())
    dispatch(active_ilet_false())
  }
  const blogactive = () => {
    dispatch(active_ana_false())
    dispatch(active_hak_false())
    dispatch(active_blog())
    dispatch(active_ilet_false())
    dispatch(watchfalse())
    setTimeout(() => {
      dispatch(watchtrue())
    }, 1000)
  }
  const dispatch = useDispatch();
  anaactive();
  return (
    <div className='w-full'>
      <div className='w-full container mx-auto p-0 m-0'>
        {/* <div className='w-full  container mx-auto p-0 m-0 max-md:hidden'>
          <div className='grid grid-col-1'>
            <h3 className='col-span-1 font-header font-bold text-center'>Uzmanlıklar</h3>
          </div>
          <div class="grid grid-cols-4">
            <div className='col-span-1 overflow-hidden p-10'>
              <img className='object-cover' src="./Images/speci-1.png" alt="" />
              <h4 className='text-center font-header font-bold'>Çift si</h4>
            </div>
            <div className='col-span-1 overflow-hidden p-10'>
              <img className='object-cover' src="./Images/speci-2.png" alt="" />
              <h4 className='text-center font-header font-bold'>Cinsel Konular</h4>
            </div>
            <div className='col-span-1 overflow-hidden p-10'>
              <img className='object-cover' src="./Images/speci-3.png" alt="" />
              <h4 className='text-center font-header font-bold'>Danışmanlık</h4>
            </div>
            <div className='col-span-1 overflow-hidden p-10'>
              <img className='object-cover' src="./Images/speci-4.png" alt="" />
              <h4 className='text-center font-header font-bold'>Çift si</h4>
            </div>
          </div>
        </div> */}
      </div>

      <div className='w-full bg-lacivert py-[7.5vh] text-white ' >
        <div className='w-full grid place-items-center container mx-auto p-0 m-0 '>
          <h3 className="grid justify-center font-header text-[3rem] max-md:text-[2rem]">Uzmanlıklar</h3>
          <div class="w-full justify-center items-center  place-items-center md:grid md:grid-cols-3 md:grid-flow-row max-md:grid-rows max-md:grid-flow-col max-md:gap-10">

            <BlogSingle blog_header_css="text-white" blog_page={"/hakkimda"} uzmanliklar={<BsPersonWalking className='w-full h-full p-2' />} blog_id={1} blog_header="İlişki" blog_image="./Images/book-glass.png" />
            <BlogSingle blog_header_css="text-white" blog_page={"/hakkimda"} uzmanliklar={<FaPersonArrowUpFromLine className='w-full h-full p-2' />} blog_id={2} blog_header="Cinsellik" blog_image="./Images/book.png" />
            <BlogSingle blog_header_css="text-white" blog_page={"/hakkimda"} uzmanliklar={<HiHeart className='w-full h-full p-2' />} blog_id={3} blog_header="Cinsel Konular" blog_image="./Images/free.png" />

          </div>
          <NavLink onClick={blogactive} to="/hakkimda" className="grid justify-center font-aydin6 font-[600] no-underline text-[1.3rem] bg-white rounded-[0.6rem] text-lacivert w-fit px-4 py-2">Hakkımda</NavLink>
        </div>
      </div>


      <div className='py-[7.5vh] md:w-[100%] max-md:w-[100%] container mx-auto'>
        {/* <img className='absolute z-[-1] max-md:w-[10rem]' src="./Images/splash.png" alt="" /> */}
        <div className='w-full   grid place-items-center container mx-auto py-[7.5vh] m-0 p-0'>
          {/* <h3 className="grid  justify-center font-header  text-[3rem]">Youtube</h3> */}
          <a className='text-lacivert' target='_blank' href="https://www.youtube.com/@Aydinpsikoloji">
            <ImYoutube2 className="grid  justify-center text-[8rem]" />
          </a>
          <div class="w-full max-md:flex-col justify-center items-center place-items-center md:grid md:grid-cols-1 md:grid-flow-row max-md:grid-rows max-md:grid-flow-col max-md:hidden">
            {/* <YoutubeVideo video_head="Deneme Bir İki" video_code="xNJQGZgL6tQ" /> */}
            {/* <SiYoutubeshorts onClick={youtube} className={`absolute md:w-[10rem] md:h-[10rem] max-md:w-[3rem] max-md:h-[3rem] text-red-700 hover:text-red-500 duration-300 cursor-pointer ${you ? "" : "hidden"}`} /> */}
            {/* <iframe width="1236" height="695" src="https://www.youtube.com/embed/xNJQGZgL6tQ" title="Kadın Mağduru Erkekler!" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> */}
            <YoutubeVideo video_head="Kadın Mağduru Erkekler" video_code="xNJQGZgL6tQ?si=Lzt1s-9FxfZODdFA" />
          </div>
          <div class="max-md:p-0 w-full max-md:flex max-md:flex-col md:hidden justify-center items-center place-items-center  md:grid-cols-3 md:grid-flow-row max-md:grid-rows max-md:grid-flow-col">
            <a target='_blank' href="https://www.youtube.com/watch?v=xNJQGZgL6tQ&t=330s" className='text-lacivert'>
              <YoutubeImg video_head="Kadın Mağduru Erkekler" video_code="xNJQGZgL6tQ" />
            </a>
            <a target='_blank' href="https://www.youtube.com/watch?v=pEVX0loEnY0&t=1s" className='text-lacivert'>
              <YoutubeImg video_head="Eril Enerji" video_code="pEVX0loEnY0" />
            </a>
            <a target='_blank' href="https://www.youtube.com/watch?v=DqDkaURvM3Y&t=713s" className='text-lacivert'>
              <YoutubeImg video_head="Dişil Enerji" video_code="DqDkaURvM3Y" />
            </a>
            <a className='w-fit hover:scale-110 duration-300' target='_blank' href="https://www.youtube.com/@Aydinpsikoloji">
              <TfiYoutube className='max-md:hidden text-lacivert w-[5rem] h-[5rem]' />
            </a>
          </div>
          <div class="max-md:p-0 w-full max-md:flex-col max-md:hidden justify-center items-center place-items-center md:grid md:grid-cols-3 md:grid-flow-row max-md:grid-rows max-md:grid-flow-col">
            <a target='_blank' href="https://www.youtube.com/watch?v=pEVX0loEnY0&t=1s" className='text-lacivert'>
              <YoutubeImg video_head="Eril Enerji" video_code="pEVX0loEnY0" />
            </a>
            <a target='_blank' href="https://www.youtube.com/watch?v=DqDkaURvM3Y&t=713s" className='text-lacivert'>
              <YoutubeImg video_head="Dişil Enerji" video_code="DqDkaURvM3Y" />
            </a>
            <a className='w-fit hover:scale-110 duration-300' target='_blank' href="https://www.youtube.com/@Aydinpsikoloji" >
              <TfiYoutube className='max-md:hidden text-lacivert w-[5rem] h-[5rem]' />
            </a>
          </div>
        </div>
      </div>
      <div className='w-full bg-lacivert py-[2.5vh] container-fluid text-white  max-md:rounded-[0] font-main' >
        <div className='w-full grid place-items-center container mx-auto p-0 m-0   max-md:border-none'>
          <div class="w-full justify-center items-center  place-items-center md:grid md:grid-cols-2 md:grid-flow-row max-md:grid-rows max-md:grid-flow-col max-md:gap-10">
            <div className='max-md:hidden'><img className='p-12 max-md:p-0 w-[75%] mx-auto max-md:rounded-[1rem]' src="./Images/buseaydin-1.jpg" alt="" /></div>

            <div className='md:p-12 max-md:py-6'><h3 className='text-center max-sm:text-[10px] max-md:text-[15px] max-lg:text-[20px] text-[22px]'>Kişiler bir problem yaşadığında kendilerine fazla yükleniyorlar ancak bunlar hayatın akışında yaşanan şeyler. Hayat tam olarak bu. Bizim hatamız bize yapılan hatalar bizim bu sorunların neresinde olduğumuz ve neresinde olmadığımız. Sağlıklı bir bakış açısı. Durumlar belki de sandığımız kadar kötü değildir ya da daha kötüdür :) ve evet bu da hayatın olağan akışında olan şeyler.
              #kötühissetmeknormaldir</h3></div>

          </div>
        </div>
      </div>
      <div className='w-full'>
        <PiDotsThreeOutlineVerticalFill className='mx-auto w-[5rem] h-[5rem] my-[2rem] text-lacivert' />
      </div>
    </div>
  )
}

export default Homepage 