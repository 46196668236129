import React from 'react'
import { Route, Routes } from "react-router-dom";
import Homepage from '../Pages/Homepage';
import About from '../Pages/About';
import SosyalMedya from '../Pages/SosyalMedya';
import Contact from '../Pages/Contact';
import { useDispatch } from 'react-redux';
import Loading from '../Mid-Components/Loading';
import { active_ana, active_ana_false } from '../Stores/active1';
import { active_hak, active_hak_false } from '../Stores/active2';
import { active_blog, active_blog_false } from '../Stores/active3';
import { active_ilet, active_ilet_false } from '../Stores/active4';
import Error from '../Pages/Error';
const Body = () => {

  const dispatch = useDispatch();

  const anahakkimda = () => {
    dispatch(active_ana_false())
    dispatch(active_hak())
    dispatch(active_blog_false())
    dispatch(active_ilet_false())
  }
  const anablog = () => {
    dispatch(active_ana_false())
    dispatch(active_hak_false())
    dispatch(active_blog())
    dispatch(active_ilet_false())
  }
  const anailetisim = () => {
    dispatch(active_ana_false())
    dispatch(active_hak_false())
    dispatch(active_blog_false())
    dispatch(active_ilet())
  }

  return (
    <div className=' p-0 '>
      <Routes>
        <Route path='/*' element={<Error />} />
        <Route path='/' element={<Homepage />} />
        <Route path='/hakkimda' element={<About />} />
        <Route path='/sosyalmedya' element={<SosyalMedya />} />
        <Route path='/iletisim' element={<Contact />} />
      </Routes>
    </div>
  )
}

export default Body;
