import React from 'react';
import { active_ana, active_ana_false } from '../Stores/active1';
import { active_hak, active_hak_false } from '../Stores/active2';
import { active_blog, active_blog_false } from '../Stores/active3';
import { active_ilet, active_ilet_false } from '../Stores/active4';
import YoutubeImg from '../Contents/YoutubeImg';
import { useDispatch } from 'react-redux';
import BlogSingle from '../Contents/BlogSingle';
import { TfiYoutube } from "react-icons/tfi";
import { ImYoutube2 } from "react-icons/im";
import { FaTiktok } from "react-icons/fa";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import { useEffect } from 'react';
import styled from 'styled-components';
import { InstagramEmbed } from 'react-social-media-embed';
import { TikTokEmbed } from 'react-social-media-embed';
import { FaInstagram } from "react-icons/fa";
import { Carousel } from 'primereact/carousel';
import Owldemo from '../Mid-Components/OwlDemo';
import { Swiper } from 'swiper/react';
import { SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-flip';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import './styles.css';

// import required modules
import { EffectFlip, Pagination, Navigation, Autoplay } from 'swiper/modules';
const SosyalMedya = () => {

  const blogactive = () => {
    dispatch(active_ana_false());
    dispatch(active_hak_false());
    dispatch(active_blog());
    dispatch(active_ilet_false());
  };

  const dispatch = useDispatch();
  blogactive();

  return (

    <div className='w-full '>
      <div className='container mx-auto pb-[40px] m-0 flex flex-col justify-center items-center'>
        <a className='text-lacivert' target='_blank' href="https://www.youtube.com/@Aydinpsikoloji">
          <ImYoutube2 className="grid  justify-center text-[8rem]" />
        </a>



        <div class=" font-header w-full justify-center items-center place-items-center md:grid md:grid-cols-3 md:grid-flow-row max-md:grid-rows max-md:grid-flow-col max-md:gap-10">
          <a target='_blank' className='text-lacivert' href="https://www.youtube.com/watch?v=xNJQGZgL6tQ&t=330s">
            <YoutubeImg video_head="Kadın Mağduru Erkekler" video_code="xNJQGZgL6tQ" />
          </a>
          <a target='_blank' className='text-lacivert' href="https://www.youtube.com/watch?v=pEVX0loEnY0">
            <YoutubeImg video_head="Eril Enerji" video_code="pEVX0loEnY0" />
          </a>
          <a target='_blank' className='text-lacivert' href="https://www.youtube.com/watch?v=DqDkaURvM3Y&t=713s">
            <YoutubeImg video_head="Dişil Enerji" video_code="DqDkaURvM3Y" />
          </a>
          <a target='_blank' className='text-lacivert' href="https://www.youtube.com/watch?v=7L9OOsw_F2M">
            <YoutubeImg video_head="İlişkiyi Bitiren 10 Davranış" video_code="7L9OOsw_F2M" />
          </a>
          <a target='_blank' className='text-lacivert' href="https://www.youtube.com/watch?v=gwqA7mfZG8Y">
            <YoutubeImg video_head="Artık Neden Sevişmiyoruz" video_code="gwqA7mfZG8Y" />
          </a>
          <a className='no-underline flex flex-col justify-center items-center py-5 text-lacivert text-[1.5rem] font-[600] font-aydin2 w-fit hover:scale-110 duration-300 max-md:w-full max-md:flex justify-center' target='_blank' href="https://www.youtube.com/@Aydinpsikoloji">
            <TfiYoutube className='text-lacivert w-[5rem] h-[5rem]' />
            Kanala Git
          </a>
        </div>
      </div>

      <div className='bg-lacivert container-fluid m-0 py-[40px] max-md:py-[0px] p-0'>
        <div className='container mx-auto p-0 m-0 flex flex-col w-full justify-center items-center '>
          
          <a className='text-white flex no-underline justify-center items-center pb-[40px] max-md:pb-[0px]' target='_blank' href="https://www.youtube.com/@Aydinpsikoloji">
            <FaInstagram className="grid p-6 justify-center text-[8rem]" />
            <h4 className='font-instagram text-[4rem]' class="d-md-flex d-none">Instagram</h4>
          </a>

          <div className='md:hidden max-md:flex justify-center items-center p-0 m-0 mx-auto'>
          <Swiper
effect={'flip'}
grabCursor={true}
autoplay={{
  delay: 3000,
  disableOnInteraction: false,
}}
loop={true}
navigation={true}
modules={[EffectFlip, Pagination, Navigation, Autoplay]}
className="mySwiper mx-auto p-0 m-0 md:hidden d-md-none d-flex justify-center items-center"
      >
        <SwiperSlide>
        <InstagramEmbed url="https://www.instagram.com/reel/C1y01uKqKFN/?igsh=d2sxamNsMmlibm5k" className="w-full"  />
        </SwiperSlide>
        <SwiperSlide>
        <InstagramEmbed url="https://www.instagram.com/reel/C6V5JS5qaLE/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==" className="w-full"  />
        
        </SwiperSlide>
        <SwiperSlide>
        <InstagramEmbed url="https://www.instagram.com/reel/C1t_WBjKPBA/?igsh=NXU1eTdjdXk2YzI5" className="w-full"  />
          
        </SwiperSlide>
        <SwiperSlide>
        <InstagramEmbed url="https://www.instagram.com/reel/C1rcERbKiub/?igsh=MW1sOTF6ODVxejg3cQ==" className="w-full"  />
        
        </SwiperSlide>
        <SwiperSlide>
        <InstagramEmbed url="https://www.instagram.com/reel/C1rD2YuqvmT/?igsh=MW12OHFveTlsbDAwaw==" className="w-full"  />

        </SwiperSlide>
        <SwiperSlide>
        <InstagramEmbed url="https://www.instagram.com/reel/CtJBBssKruU/?igsh=MTY2aDBtb296N2RhZA==" className="w-full"  />
          
        </SwiperSlide>
      </Swiper>
          </div>


          <div className='max-md:hidden p-0 m-0' class="row">
            <div class="col-md-4 mb-5 d-md-flex d-none" style={{ display: 'flex', justifyContent: 'center' }}>
              <InstagramEmbed url="https://www.instagram.com/reel/C1y01uKqKFN/?igsh=d2sxamNsMmlibm5k" width={328} />
            </div>
            <div class="col-md-4 mb-5 d-md-flex d-none" style={{ display: 'flex', justifyContent: 'center' }}>
              <InstagramEmbed url="https://www.instagram.com/reel/C6V5JS5qaLE/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==" width={328} />
            </div>
            <div class="col-md-4 mb-5 d-md-flex d-none" style={{ display: 'flex', justifyContent: 'center' }}>
              <InstagramEmbed url="https://www.instagram.com/reel/C1t_WBjKPBA/?igsh=NXU1eTdjdXk2YzI5" width={328} />
            </div>
            <div class="col-md-4 mb-5 d-md-flex d-none" style={{ display: 'flex', justifyContent: 'center' }}>
              <InstagramEmbed url="https://www.instagram.com/reel/C1rcERbKiub/?igsh=MW1sOTF6ODVxejg3cQ==" width={328} />
            </div>
            <div class="col-md-4 mb-5 d-md-flex d-none" style={{ display: 'flex', justifyContent: 'center' }}>
              <InstagramEmbed url="https://www.instagram.com/reel/C1rD2YuqvmT/?igsh=MW12OHFveTlsbDAwaw==" width={328} />
            </div>
            <div class="col-md-4 mb-5 d-md-flex d-none" style={{ display: 'flex', justifyContent: 'center' }}>
              <InstagramEmbed url="https://www.instagram.com/reel/CtJBBssKruU/?igsh=MTY2aDBtb296N2RhZA==" width={328} />
            </div>

          </div>
          
        </div>

      </div>
      
      <div className='container mx-auto p-0 m-0 flex flex-col justify-center items-center '>
        <div className='w-full justify-center items-center  place-items-center md:grid md:grid-cols-3 md:grid-flow-row max-md:grid-rows max-md:grid-flow-col'>
          <div class="flex  max-md:flex-col p-4 container justify-start my-6 w-full">
            <div className=' h-fit  flex justify-center items-center w-[25%] max-md:w-[100%]'>
              <img className='rounded-full w-[100%] max-md:h-[75px] max-md:w-[75px]' src="./Images/psikologbuseaydin.jpeg" alt="" />
            </div>
            <div className=' h-fit flex flex-col justify-between items-start max-md:items-center text-start w-[75%] max-md:w-[100%] mx-auto pl-4 max-md:pl-[0]'>
              <h3 className='w-fit'>psikologbuseofficial</h3>
              <h5 className='w-fit font-[400]'>psikologbuseofficial</h5>
              <div className='flex justify-start items-center w-fit mt-[10px]'>

                <a href='https://www.tiktok.com/@psikologbuseofficial?lang=en' className='bg-tiktokfollow text-white w-[150px] py-[5px] rounded-md text-center'>TikTok</a>
              </div>
            </div>
          </div>

          <div class="flex  max-md:flex-col p-4 container justify-start my-6 w-full">
            <div className=' h-fit flex justify-center items-center w-[25%] max-md:w-[100%]'>
              <img className='rounded-full w-[100%] max-md:h-[75px] max-md:w-[75px]' src="./Images/buseaydininsta.jpg" alt="" />
            </div>
            <div className=' h-fit flex flex-col justify-between items-start max-md:items-center text-start w-[75%] max-md:w-[100%] mx-auto pl-4 max-md:pl-[0]'>
              <h3 className='w-fit'>psikologbuseaydin</h3>
              <h5 className='w-fit font-[400]'>@psikologbuseaydin</h5>
              <div className='flex justify-start items-center w-fit mt-[10px]'>
                <a href='https://www.instagram.com/psikologbuseaydin/?hl=en' className='bg-instafollow text-white w-[150px] py-[5px] rounded-md text-center'>İnstagram</a>
              </div>
            </div>

          </div>
          <div class="flex max-md:flex-col container p-4 justify-start my-6 w-full">
            <div className=' h-fit flex justify-center items-center w-[25%] max-md:w-[100%]'>
              <img className='rounded-full w-[100%] max-md:h-[75px] max-md:w-[75px]' src="./Images/psikologbuseaydin.jpeg" alt="" />
            </div>
            <div className=' h-fit flex flex-col justify-between items-start max-md:items-center text-start w-[75%] max-md:w-[100%] mx-auto pl-4 max-md:pl-[0]'>
              <h3 className='w-fit'>Aydın Psikoloji</h3>
              <h5 className='w-fit font-[400]'>@Aydinpsikoloji</h5>
              <div className='flex justify-start items-center w-fit mt-[10px]'>
                <a href='https://www.youtube.com/@Aydinpsikoloji' className='bg-red-600 text-white w-[150px] py-[5px] rounded-md text-center'>Youtube</a>
              </div>
            </div>
          </div>
        </div>


      </div>
      <div className='w-full'>
        <PiDotsThreeOutlineVerticalFill className='mx-auto w-[5rem] h-[5rem] my-[2rem] text-lacivert' />
      </div>
    </div>
  )
}

export default SosyalMedya